import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { generateImage, clearImageHistory } from '../utils/imageGeneration';
import { generatePageTitle } from '../utils/titleGeneration';
import { savePage, getSavedPages, type Page } from '../utils/storage';
import { ChoiceTile } from '../components/ChoiceTile';

export function EditPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [pageName, setPageName] = useState('');
  const [currentChoice, setCurrentChoice] = useState('');
  const [choices, setChoices] = useState<Array<{ text: string; imageUrl: string }>>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState<number | null>(null);

  useEffect(() => {
    const pages = getSavedPages();
    const page = pages.find(p => p.id === id);
    if (page) {
      setPageName(page.name);
      setChoices(page.choices);
    } else {
      navigate('/');
    }
  }, [id, navigate]);

  const addChoice = async () => {
    if (currentChoice && choices.length < 8) {
      setIsGenerating(true);
      try {
        const imageUrl = await generateImage(currentChoice);
        setChoices([
          ...choices,
          {
            text: currentChoice,
            imageUrl: imageUrl
          }
        ]);
        setCurrentChoice('');
      } catch (error) {
        console.error('Error adding choice:', error);
      } finally {
        setIsGenerating(false);
      }
    }
  };

  const handleDeleteChoice = (index: number) => {
    setChoices(choices.filter((_, i) => i !== index));
  };

  const handleAlternateImage = async (index: number) => {
    if (isGenerating) return;
    
    try {
      setIsGenerating(true);
      const choiceText = choices[index].text;
      clearImageHistory(choiceText);
      
      const newImageUrl = await generateImage(choiceText);
      const newChoices = [...choices];
      newChoices[index] = {
        ...newChoices[index],
        imageUrl: newImageUrl
      };
      setChoices(newChoices);
    } catch (error) {
      console.error('Error generating alternate image:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && selectedChoiceIndex !== null) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newChoices = [...choices];
        newChoices[selectedChoiceIndex] = {
          ...newChoices[selectedChoiceIndex],
          imageUrl: reader.result as string
        };
        setChoices(newChoices);
        setSelectedChoiceIndex(null);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleTextChange = (index: number, newText: string) => {
    const newChoices = [...choices];
    newChoices[index] = {
      ...newChoices[index],
      text: newText
    };
    setChoices(newChoices);
  };

  const handleSave = () => {
    if (choices.length > 0 && id) {
      const pages = getSavedPages();
      const pageIndex = pages.findIndex(p => p.id === id);
      if (pageIndex !== -1) {
        const updatedPage: Page = {
          id,
          name: pageName.trim() || generatePageTitle(choices),
          choices,
          favorite: pages[pageIndex].favorite
        };
        pages[pageIndex] = updatedPage;
        savePage(pages);
        navigate('/', { replace: true });
      }
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-blue-600 text-white p-4 flex items-center">
        <button onClick={() => navigate(-1)} className="mr-4">
          ←
        </button>
        <h1 className="text-2xl font-bold">Edit Board</h1>
      </header>

      <main className="p-4 space-y-4">
        <div>
          <label className="block text-lg mb-2">Board Name</label>
          <input
            type="text"
            value={pageName}
            onChange={(e) => setPageName(e.target.value)}
            className="w-full p-2 rounded-lg border"
            placeholder="Enter board name"
          />
        </div>

        <div>
          <label className="block text-lg mb-2">Add Choice</label>
          <div className="flex gap-2">
            <input
              type="text"
              value={currentChoice}
              onChange={(e) => setCurrentChoice(e.target.value)}
              className="flex-1 p-2 rounded-lg border"
              placeholder="Enter a choice"
              disabled={isGenerating}
            />
            <button 
              onClick={addChoice} 
              className="nav-button"
              disabled={isGenerating || choices.length >= 8}
            >
              {isGenerating ? 'Generating...' : 'Add'}
            </button>
          </div>
        </div>

        <input
          type="file"
          ref={fileInputRef}
          onChange={handleImageUpload}
          accept="image/*"
          className="hidden"
        />

        <div>
          <h2 className="text-lg font-semibold mb-2">Current Choices:</h2>
          <div className="grid grid-cols-2 gap-4">
            {choices.map((choice, index) => (
              <ChoiceTile
                key={index}
                text={choice.text}
                imageUrl={choice.imageUrl}
                onDelete={() => handleDeleteChoice(index)}
                onAlternateImage={() => handleAlternateImage(index)}
                onUploadImage={() => {
                  setSelectedChoiceIndex(index);
                  fileInputRef.current?.click();
                }}
                onTextChange={(text) => handleTextChange(index, text)}
                isGenerating={isGenerating}
                editable={true}
              />
            ))}
          </div>
        </div>

        <button 
          onClick={handleSave} 
          className="w-full nav-button mt-4"
        >
          Save Changes
        </button>
      </main>
    </div>
  );
}

export default EditPage;