import { ANALYTICS_CONFIG } from './config';
import { AnalyticsLogger } from './logger';
import type { AnalyticsEvent, PageViewData } from './types';

export class AnalyticsService {
  private static initialized = false;

  static initialize(): void {
    if (this.initialized || !ANALYTICS_CONFIG.ENABLED) return;

    try {
      // Add Google Analytics script
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${ANALYTICS_CONFIG.GA_TRACKING_ID}`;
      document.head.appendChild(script);

      // Initialize gtag
      window.dataLayer = window.dataLayer || [];
      window.gtag = function() {
        window.dataLayer.push(arguments);
      };
      window.gtag('js', new Date());
      window.gtag('config', ANALYTICS_CONFIG.GA_TRACKING_ID);

      this.initialized = true;
      
      if (ANALYTICS_CONFIG.DEBUG) {
        console.log('Analytics initialized with ID:', ANALYTICS_CONFIG.GA_TRACKING_ID);
      }
    } catch (error) {
      AnalyticsLogger.logError(error);
    }
  }

  static trackEvent(
    category: string,
    action: string,
    label?: string,
    value?: number
  ): void {
    if (!ANALYTICS_CONFIG.ENABLED) return;

    const event: AnalyticsEvent = { category, action, label, value };
    
    try {
      if (window.gtag) {
        window.gtag('event', action, {
          event_category: category,
          event_label: label,
          value: value,
        });
        AnalyticsLogger.logEvent(event);
      }
    } catch (error) {
      AnalyticsLogger.logError(error);
    }
  }

  static trackPageView(pageName: string): void {
    if (!ANALYTICS_CONFIG.ENABLED) return;

    const pageData: PageViewData = {
      pageName,
      pageLocation: window.location.pathname
    };

    try {
      if (window.gtag) {
        window.gtag('event', 'page_view', {
          page_title: pageName,
          page_location: window.location.pathname
        });
        AnalyticsLogger.logPageView(pageData);
      }
    } catch (error) {
      AnalyticsLogger.logError(error);
    }
  }
}