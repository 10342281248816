import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import { CreatePage } from './pages/CreatePage';
import { ChoicesPage } from './pages/ChoicesPage';
import EditPage from './pages/EditPage';
import { AboutPage } from './pages/AboutPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/create" element={<CreatePage />} />
      <Route path="/choices/:id" element={<ChoicesPage />} />
      <Route path="/edit/:id" element={<EditPage />} />
      <Route path="/about" element={<AboutPage />} />
    </Routes>
  );
}

export default App;