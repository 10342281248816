import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export function AboutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Load Buy Me a Coffee widget script
    const script = document.createElement('script');
    script.src = "https://cdnjs.buymeacoffee.com/1.0.0/widget.prod.min.js";
    script.setAttribute('data-name', 'BMC-Widget');
    script.setAttribute('data-cfasync', 'false');
    script.setAttribute('data-id', 'adelsolar');
    script.setAttribute('data-description', 'Support me on Buy me a coffee!');
    script.setAttribute('data-message', 'Thanks for your support!');
    script.setAttribute('data-color', '#5F7FFF');
    script.setAttribute('data-position', 'Right');
    script.setAttribute('data-x_margin', '18');
    script.setAttribute('data-y_margin', '18');
    
    document.body.appendChild(script);

    // Cleanup function to remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
      // Also remove the widget container if it exists
      const widget = document.querySelector('.bmc-btn-container');
      if (widget) {
        widget.remove();
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <header className="bg-blue-600 text-white p-4 flex items-center">
        <button onClick={() => navigate(-1)} className="mr-4">
          ←
        </button>
        <h1 className="text-2xl font-bold">About Quik Boards</h1>
      </header>

      <main className="flex-1 p-4 max-w-2xl mx-auto">
        <div className="bg-white rounded-lg shadow-md p-6 space-y-6">
          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800">Our Mission</h2>
            <p className="text-gray-600">
              Quik Boards is a communication aid designed to help nonverbal individuals express their choices and needs effectively. 
              Our mission is to make communication more accessible using the devices you already have and without the need to download special apps.
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800">Contact</h2>
            <p className="text-gray-600">
              Have questions or suggestions? We'd love to hear from you!<br />
              <a 
                href="mailto:hey@quikboards.com" 
                className="text-blue-600 hover:text-blue-800 transition-colors"
              >
                hey@quikboards.com
              </a>
            </p>
          </div>

          <div className="space-y-4">
            <h2 className="text-xl font-semibold text-gray-800">Say Thanks</h2>
            <p className="text-gray-600">
              If you find Quik Boards helpful and would like to support its development, consider buying me a coffee! Your support helps keep this project going and enables new features.
            </p>
            <div className="flex justify-center pt-2">
              <a 
                href="https://www.buymeacoffee.com/adelsolar" 
                target="_blank" 
                rel="noopener noreferrer"
                className="inline-flex items-center px-6 py-3 bg-[#FFDD00] text-gray-900 font-semibold rounded-lg hover:bg-[#FFED4F] transition-colors duration-200"
              >
                <img 
                  src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg" 
                  alt="Buy me a coffee" 
                  className="h-6 mr-2"
                />
                Buy me a coffee
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}