import { isProduction } from '../../utils/environment';
import type { AnalyticsEvent, PageViewData } from './types';

/**
 * Development logging utility for analytics
 */
export const AnalyticsLogger = {
  logEvent(event: AnalyticsEvent): void {
    if (!isProduction) {
      console.log('Analytics Event:', event);
    }
  },

  logPageView(data: PageViewData): void {
    if (!isProduction) {
      console.log('Page View:', data);
    }
  },

  logError(error: unknown): void {
    if (!isProduction) {
      console.error('Analytics Error:', error);
    }
  }
};