import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="mt-auto py-4 px-4 border-t bg-white">
      <div className="max-w-4xl mx-auto flex justify-between items-center text-gray-600 text-sm">
        <div className="flex items-center gap-4">
          <Link to="/about" className="hover:text-blue-600 transition-colors">
            About Quik Boards
          </Link>
        </div>
        <div>
          © {new Date().getFullYear()} Quik Boards
        </div>
      </div>
    </footer>
  );
}