import type { FC } from 'react';

interface ChoiceTileProps {
  text: string;
  imageUrl: string;
  onDelete: () => void;
  onAlternateImage: () => void;
  onUploadImage: () => void;
  onTextChange?: (text: string) => void;
  isGenerating?: boolean;
  editable?: boolean;
}

export const ChoiceTile: FC<ChoiceTileProps> = ({
  text,
  imageUrl,
  onDelete,
  onAlternateImage,
  onUploadImage,
  onTextChange,
  isGenerating,
  editable = false
}) => {
  return (
    <div className="bg-white rounded-lg overflow-hidden shadow">
      <div className="relative">
        <img 
          src={imageUrl} 
          alt={text} 
          className="w-full h-40 object-cover"
        />
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={onAlternateImage}
            className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Get alternate image"
            disabled={isGenerating}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z" clipRule="evenodd" />
            </svg>
          </button>
          <button
            onClick={onUploadImage}
            className="p-2 bg-white rounded-full shadow-md hover:bg-gray-100 transition-colors duration-200"
            aria-label="Upload new image"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
              <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z" />
              <path d="M9 13h2v5a1 1 0 11-2 0v-5z" />
            </svg>
          </button>
        </div>
      </div>
      <div className="p-4">
        {editable ? (
          <div className="relative">
            <input
              type="text"
              value={text}
              onChange={(e) => onTextChange?.(e.target.value)}
              className="w-full p-2 pr-10 rounded border"
            />
            <button
              onClick={onDelete}
              className="absolute right-2 top-1/2 -translate-y-1/2 text-red-600 hover:text-red-800 transition-colors duration-200"
              aria-label="Delete choice"
            >
              ✕
            </button>
          </div>
        ) : (
          <div className="text-lg text-center">{text}</div>
        )}
      </div>
    </div>
  );
};